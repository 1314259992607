import { toast } from 'react-toastify';

import type { Answer, Question } from '../../shared/types';
import type { CreateEditAnswer } from '../../utils/schema';

import { addAnswer, editAnswer, editQuestion } from '../../store/actions';
import { getNewId } from '../../utils/getNewId';
import { mapCreateEditFormStateToAnswerState } from '../../utils/mapAnswerStateToFormState';
import { useQuestionsContext } from '../useQuestionsContext/useQuestionsContext';

type UseCreateEditAnswerAction = {
  editAnswer: (formData: CreateEditAnswer, callback: () => void) => void;
  addAnswer: (formData: CreateEditAnswer, callback: () => void) => void;
};

type UseCreateEditAnswerActionProps = Readonly<{
  questionId: string;
  answerToEdit?: Answer;
  question: Question;
}>;

export const useCreateEditAnswerAction = ({
  answerToEdit,
  questionId,
  question,
}: UseCreateEditAnswerActionProps): UseCreateEditAnswerAction => {
  const { dispatch, state } = useQuestionsContext();

  const _editAnswer = (formData: CreateEditAnswer, callback: () => void) => {
    const parsedAnswer = mapCreateEditFormStateToAnswerState(formData);
    if (!answerToEdit) {
      throw new Error('Answer to edit is not defined');
    }

    const isDefaultAnswer = Boolean(formData.switchControls?.isDefaultAnswer);

    if (isDefaultAnswer || question.defaultAnswers?.[0] === answerToEdit.value) {
      const updatedQuestion = { ...question, defaultAnswers: isDefaultAnswer ? [answerToEdit.value] : [] } as Question;
      dispatch(editQuestion({ questionId, updatedQuestion }));
    }

    dispatch(editAnswer({ questionId, answerId: answerToEdit.id, updatedAnswer: { ...parsedAnswer, id: answerToEdit.id } }));
    toast(`Answer edited successfully`, { type: 'success' });
    callback();
  };

  const _addAnswer = (formData: CreateEditAnswer, callback: () => void) => {
    const answers = state.questions.find((question) => question.id === questionId)?.answers;
    const parsedAnswer = mapCreateEditFormStateToAnswerState(formData);

    const isDefaultAnswer = Boolean(formData.switchControls?.isDefaultAnswer);
    if (isDefaultAnswer) {
      const updatedQuestion = { ...question, defaultAnswers: [parsedAnswer.value] } as Question;
      dispatch(editQuestion({ questionId, updatedQuestion }));
    }

    dispatch(addAnswer({ questionId: questionId, answer: { ...parsedAnswer, id: getNewId(answers ?? []) } }));
    toast(`Answer added successfully`, { type: 'success' });
    callback();
  };

  return { editAnswer: _editAnswer, addAnswer: _addAnswer };
};
