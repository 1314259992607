import { z } from 'zod';

import { NestedFieldName } from '../shared/types';

const commonQuestionValidationSchema = z.object({
  text: z.string().nonempty('Question Text is required'),
  section: z.string().nonempty('Section (Grouped on CR) is required'),
  buyerTranslation: z.string().nonempty('Buyer Translation is required'),
  subSection: z.string().optional(),
  helpText: z.string().optional(),
  type: z.union([z.literal('single'), z.literal('multi')]),
  artMapping: z
    .object({
      artCode: z
        .object({
          itemCode: z.string().optional(),
          itemSubCode: z.string().optional(),
          itemSearch: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
  defaultAnswers: z.array(z.string()).optional(),
  answers: z
    .array(
      z.object({
        id: z.string().nonempty('Answer id is required'),
        value: z.string().optional(),
        hideBuyerDisplay: z.boolean().optional(),
        answerGroups: z.array(z.string()).optional(),
        requiredQuestions: z.array(z.string()).optional(),
        nestedQuestions: z.array(z.string()).optional(),
        connotation: z.number().int().min(-1).max(0).optional(),
        maxQuantity: z.number().int().min(0).optional(),
        chooseQuantity: z.boolean().optional(),
        notesLabel: z.string().min(1, 'Note Label is required').optional(),
        showNotes: z.boolean().optional(),
        gradeInfo: z
          .array(
            z.object({
              damages: z.object({ itemDesc: z.string(), damageDesc: z.string(), severityDesc: z.string() }).optional(),
              tireInfo: z.object({ tireLocation: z.string(), tireDepth: z.string() }).optional(),
            }),
          )
          .optional(),
        metaData: z
          .object({
            takePhoto: z
              .object({
                type: z.string().optional(),
                title: z.string().optional(),
                description: z.string().optional(),
              })
              .optional(),
            artCode: z
              .object({
                itemCode: z.string().optional(),
                itemSubCode: z.string().optional(),
                damageCode: z.string().optional(),
                severityCode: z.string().optional(),
                itemSearch: z.string().optional(),
              })
              .optional(),
          })
          .optional(),
        gradeFlag: z.record(z.string(), z.boolean()).optional(),
      }),
    )
    .optional(),
  switchControls: z
    .object({
      isMultipleResponses: z.boolean().optional(),
      isFilters: z.boolean().optional(),
      isClientPreferences: z.boolean().optional(),
      isGuideImages: z.boolean().optional(),
      isCopyAnswers: z.boolean().optional(),
    })
    .optional(),
});

const createEditQuestionTagsSchema = z.object({
  tags: z.array(z.string()).refine((tags) => tags.length > 0, {
    message: 'Filter Parameters is required',
  }),
});

const guideImagesCategorySchema = z.array(z.object({ url: z.string(), tags: z.array(z.string()).optional() })).optional();
const createEditQuestionGuideImagesSchema = z.object({
  guideImages: z
    .object({
      car: guideImagesCategorySchema,
      truck: guideImagesCategorySchema,
      shared: guideImagesCategorySchema,
      suv: guideImagesCategorySchema,
      van: guideImagesCategorySchema,
    })
    .optional()
    .refine((guideImages) => guideImages && Object.values(guideImages).some((image) => image?.length >= 1), {
      message: 'At least one section is required',
    }),
});

const createEditQuestionClientPreferencesSchema = z.object({
  clientValue: z
    .string()
    .min(1, 'Client Value is required')
    .refine((value) => /^\d+$/.test(value), { message: 'Value can contain only digits' }),
  clientAccountNumber: z
    .string()
    .min(1, 'Client Account Number is required')
    .refine((value) => String(value).length === 7 && /^\d+$/.test(value), {
      message: 'Please enter valid Seller Number',
    }),
});

export type CommonQuestion = z.infer<typeof commonQuestionValidationSchema> &
  z.infer<typeof createEditQuestionGuideImagesSchema> &
  z.infer<typeof createEditQuestionTagsSchema> &
  z.infer<typeof createEditQuestionClientPreferencesSchema>;

export const createEditAnswerValidationSchema = z.object({
  value: z.string().nonempty('Answer Text is required'),
  helpText: z.string().optional(),
  hideBuyerDisplay: z.boolean().optional(),
  connotation: z.number().int().min(-1).max(0).optional(),
  switchControls: z
    .object({
      isRequiresPhoto: z.boolean().optional(),
      isNestQuestion: z.boolean().optional(),
      isGradeAdjust: z.boolean().optional(),
      isFreeFormText: z.boolean().optional(),
      isNumericEntry: z.boolean().optional(),
      isClientPreferences: z.boolean().optional(),
      isFilters: z.boolean().optional(),
      isDefaultAnswer: z.boolean().optional(),
      isAnnouncements: z.boolean().optional(),
      isMetadata: z.boolean().optional(),
    })
    .optional(),
  metaData: z
    .object({
      takePhoto: z
        .object({
          type: z.string().optional(),
          title: z.string().optional(),
          description: z.string().optional(),
        })
        .optional(),
      artCode: z
        .object({
          itemCode: z.string().optional(),
          itemSubCode: z.string().optional(),
          damageCode: z.string().optional(),
          severityCode: z.string().optional(),
          itemSearch: z.string().optional(),
        })
        .optional(),
    })
    .passthrough()
    .optional(),
  damages: z.object({ itemDesc: z.string(), damageDesc: z.string(), severityDesc: z.string() }).optional(),
  tireInfo: z.object({ tireLocation: z.string(), tireDepth: z.string() }).optional(),
  gradeFlag: z.record(z.string(), z.boolean()).optional(),
  tags: z.array(z.string()),
  answerGroups: z.array(z.string()).optional(),
});

const createEditAnswerMetadataTakePhotoSchema = z.object({
  metaData: z
    .object({
      takePhoto: z
        .object({
          type: z.string().nonempty(),
          title: z.string().nonempty('Photo Title is required'),
          description: z.string().nonempty('Photo Description is required'),
        })
        .required(),
    })
    .required(),
});

const createEditAnswerClientPreferencesSchema = z.object({
  clientDamageItem: z
    .string()
    .refine((value) => String(value).length === 0 || /^\d+$/.test(value), {
      message: 'Value can contain only digits',
    })
    .optional(),
  modelFilter: z.string().optional(),
});

const createEditAnswerFilterParametersSchema = z.object({
  filterParameters: z.array(z.string()).optional(),
});

const nestedQuestionSubsequentValidationSchema = z.object({
  nestingType: z.literal('requiredQuestions'),
  requiredQuestions: z.array(z.string()).nonempty(),
});

const nestedQuestionDropdownValidationSchema = z.object({
  nestingType: z.literal('nestedQuestions'),
  nestedQuestions: z.string().nonempty(),
});

const nestedQuestionValidationSchema = z.discriminatedUnion('nestingType', [
  nestedQuestionSubsequentValidationSchema,
  nestedQuestionDropdownValidationSchema,
]);

const nestedQuestionDetailsValidationSchema = z.object({
  nestedQuestionDetails: nestedQuestionValidationSchema.optional(),
});

const artCodeValidationSchema = z.object({
  metaData: z
    .object({
      artCode: z
        .object({
          itemCode: z.string().min(1, 'Item description is required'),
          itemSubCode: z.string().min(1),
          damageCode: z.string().min(1, 'Damage Code is required'),
          severityCode: z.string().min(1, 'Severity Code is required'),
          itemSearch: z.string().min(1, 'Item Search is required'),
        })
        .optional(),
    })
    .optional(),
  damages: z.object({ itemDesc: z.string(), damageDesc: z.string(), severityDesc: z.string() }).optional(),
  tireInfo: z.object({ tireLocation: z.string(), tireDepth: z.string() }).optional(),
  gradeFlag: z.record(z.string(), z.boolean()).optional(),
});

const freeFormTextSchema = z.object({
  notesLabel: z.string().min(0, 'Note Label is required').optional(),
  showNotes: z.boolean().optional(),
});

const numericEntryValidationSchema = z.object({
  chooseQuantity: z.boolean(),
  maxQuantity: z.number().int().min(0).max(999),
});

const announcementsValidationSchema = z.object({
  announcements: z
    .array(z.string())
    .optional()
    .refine((announcements) => announcements?.length ?? 0 > 0, {
      message: 'Announcements is required',
    }),
});

export type CreateEditAnswer = z.infer<typeof createEditAnswerValidationSchema> &
  z.infer<typeof nestedQuestionDetailsValidationSchema> &
  z.infer<typeof numericEntryValidationSchema> &
  z.infer<typeof createEditAnswerClientPreferencesSchema> &
  z.infer<typeof freeFormTextSchema> &
  z.infer<typeof createEditAnswerFilterParametersSchema> &
  z.infer<typeof announcementsValidationSchema>;

const getIsRequiresPhotoSchema = (isRequiresPhotos: boolean) => {
  if (isRequiresPhotos) {
    return createEditAnswerMetadataTakePhotoSchema;
  }

  return null;
};

const getIsGradeAdjustSchema = (isGradeAdjust?: boolean) => {
  if (isGradeAdjust) {
    return artCodeValidationSchema;
  }

  return null;
};

const getIsFreeFormTextSchema = (isFreeFormText: boolean) => {
  if (isFreeFormText) {
    return freeFormTextSchema;
  }

  return null;
};

const getIsNumericEntrySchema = (isNumericEntry?: boolean) => {
  if (isNumericEntry) {
    return numericEntryValidationSchema;
  }

  return null;
};

const getIsAnnouncementsSchema = (isAnnouncements?: boolean) => {
  if (isAnnouncements) {
    return announcementsValidationSchema;
  }

  return null;
};

const getIsClientPreferencesSchema = (isClientPreferences: boolean) => {
  if (isClientPreferences) {
    return createEditAnswerClientPreferencesSchema;
  }

  return null;
};

const getIsFiltersSchema = (isFilters: boolean) => {
  if (isFilters) {
    return createEditAnswerFilterParametersSchema;
  }

  return null;
};

type MergeSchemasParams = {
  isRequiresPhotos: boolean;
  isNestQuestion: boolean;
  isFreeFormText: boolean;
  isClientPreferences: boolean;
  isFilters: boolean;
  isGradeAdjust?: boolean;
  isNumericEntry?: boolean;
  isAnnouncements?: boolean;
};

export const mergeCreateEditAnswerSchemas = ({
  isRequiresPhotos,
  isNestQuestion,
  isClientPreferences,
  isFreeFormText,
  isFilters,
  isGradeAdjust,
  isNumericEntry,
  isAnnouncements,
}: MergeSchemasParams) => {
  const schemas = [
    getIsRequiresPhotoSchema(isRequiresPhotos),
    getIsGradeAdjustSchema(isGradeAdjust),
    getIsClientPreferencesSchema(isClientPreferences),
    getIsFiltersSchema(isFilters),
    getIsNumericEntrySchema(isNumericEntry),
    getIsFreeFormTextSchema(isFreeFormText),
    getIsAnnouncementsSchema(isAnnouncements),
  ];

  const baseSchema = createEditAnswerValidationSchema.extend(schemas.reduce((acc, schema) => ({ ...acc, ...schema?.shape }), {}));

  if (!isNestQuestion) {
    return baseSchema;
  }

  return baseSchema
    .extend({
      ...nestedQuestionDetailsValidationSchema.shape,
    })
    .superRefine((data) => {
      if (data.nestedQuestionDetails?.nestingType === NestedFieldName.requiredQuestions) {
        nestedQuestionSubsequentValidationSchema.parse(data.nestedQuestionDetails);
      } else {
        nestedQuestionDropdownValidationSchema.parse(data.nestedQuestionDetails);
      }
    });
};

type MergeCreateEditQuestionSchemasParams = Readonly<{
  isFilters: boolean;
  isGuideImages: boolean;
  isClientPreferences: boolean;
}>;

type MergeCopyQuestionSchemasParams = Readonly<{
  isFilters: boolean;
  isGuideImages: boolean;
  isClientPreferences: boolean;
  isCopyAnswers: boolean;
}>;

export const mergeCreateEditQuestionSchemas = ({ isFilters, isGuideImages, isClientPreferences }: MergeCreateEditQuestionSchemasParams) => {
  let schema = commonQuestionValidationSchema;

  if (isFilters) {
    schema = schema.extend({
      ...createEditQuestionTagsSchema.shape,
    });
  }

  if (isGuideImages) {
    schema = schema.extend({
      ...createEditQuestionGuideImagesSchema?.shape,
    });
  }

  if (isClientPreferences) {
    schema = schema.extend({
      ...createEditQuestionClientPreferencesSchema?.shape,
    });
  }

  return schema.superRefine((data) => {
    if (!isFilters || !isGuideImages || !isClientPreferences) {
      return commonQuestionValidationSchema.parse(data);
    }

    return schema.parse(data);
  });
};

export const mergeCopyQuestionSchemas = ({
  isFilters,
  isGuideImages,
  isClientPreferences,
  isCopyAnswers,
}: MergeCopyQuestionSchemasParams) => {
  let schema = commonQuestionValidationSchema;

  if (isFilters) {
    schema = schema.extend({
      ...createEditQuestionTagsSchema.shape,
    });
  }

  if (isGuideImages) {
    schema = schema.extend({
      ...createEditQuestionGuideImagesSchema?.shape,
    });
  }

  if (isClientPreferences) {
    schema = schema.extend({
      ...createEditQuestionClientPreferencesSchema?.shape,
    });
  }

  return schema.superRefine((data) => {
    if (!isFilters || !isGuideImages || !isClientPreferences || !isCopyAnswers) {
      return commonQuestionValidationSchema.parse(data);
    }
    return schema.parse(data);
  });
};

export const questionSetSettingsSchema = z.object({
  autoTag: z.boolean().optional(),
  asIs: z.boolean().optional(),
  metaData: z.record(z.string(), z.unknown()).optional(),
});
