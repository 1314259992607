import { zodResolver } from '@hookform/resolvers/zod';
import { type FC, type ReactElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

import type { UpdateModalDataType, StatusType } from './shared/types';
import type { FormByIdAllEnvs } from '../../api/queries/forms/forms.types';
import type { OptionType } from '../../common/types';

import { DeployFormModal } from './components/DeployFormModal';
import { ScheduleFormModal } from './components/ScheduleFormModal';
import { UpdateFormModal } from './components/UpdateFormModal';
import { capitalizeFirstLetter, updateEnvDropDown } from './helpers';
import { useDeploymentManagerStatusTable } from './hooks/useDeploymentManagerStatusTable';
import { useDeploymentManagerTable } from './hooks/useDeploymentManagerTable';
import { useForms } from '../../api/hooks/useForms';
import { useSyncFormsData } from '../../api/hooks/useSyncFormData';
import { useTestLatestFormVersions } from '../../api/hooks/useTestLatestFormVersions';
import { useUpdateForm } from '../../api/hooks/useUpdateForm';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { CommonTable } from '../../components/CommonTable/CommonTable';
import { Header } from '../../components/Header/Header';
import { Page } from '../../components/Page/Page';
import { FormControl } from '../../modules/form/components/FormControl/FormControl';
import { useModalState } from '../../modules/form/hooks/useModalState';
import { useFormByIdAllEnvs } from '../QuestionsPage/hooks/useFormByIdAllEnvs/useFormByIdAllEnvs';
import './grid.css';

const resolver = zodResolver(
  z.object({
    name: z.string().nonempty('Name is required'),
    description: z.string().nonempty('Version is required'),
  }),
);

const priority = ['int1', 'int5', 'uat', 'prod'];

const getHighestEnvironment = (formDataById: FormByIdAllEnvs): string => {
  return [...priority].reverse().find((env) => env in formDataById) || '';
};

export const DeploymentManager: FC = (): ReactElement => {
  const location = useLocation();

  const pathname = location.pathname;
  const parts = pathname.split('/');
  const formName = parts[2];

  const { isFetching: isFetchingRecent, data: recentFormData } = useTestLatestFormVersions({ formType: formName });

  const environments = useMemo(
    () =>
      recentFormData
        ? Object.keys(recentFormData).map((item) => ({
            ...recentFormData[item],
            environment: item === 'UAT' ? item.toUpperCase() : item.charAt(0).toUpperCase() + item.slice(1),
          }))
        : [],
    [recentFormData],
  );

  const environementsTable = useDeploymentManagerTable(environments);

  const { control } = useForm({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const { isFetching: isFetchingFormSearchData, data: formSearchData } = useForms({
    formType: formName,
    enabled: Boolean(formName),
    env: 'int1',
  });

  const [disableFormActivity, setDisableFormActivity] = useState(true);
  const [selectedVersion, setSelectedVersion] = useState<OptionType | undefined>({ value: '', label: '' });
  const { isFetching: isFetchingFormByVersion, data: formByVersion } = useFormByIdAllEnvs({ formId: selectedVersion?.value || '' });

  const { mutate: mutateUpdateForm, isLoading: isFetchingUpdateForm } = useUpdateForm();

  let highestEnvironment: string = formByVersion ? getHighestEnvironment(formByVersion) : '';
  highestEnvironment =
    highestEnvironment === 'UAT'
      ? highestEnvironment.toUpperCase()
      : highestEnvironment.charAt(0).toUpperCase() + highestEnvironment.slice(1);

  const statusEnvironements: Array<StatusType> = useMemo(
    () => (formByVersion ? [{ id: '1', status: capitalizeFirstLetter(formByVersion?.int1?.qaStatus) || '-', highestEnvironment }] : []),
    [formByVersion],
  );

  const statusTable = useDeploymentManagerStatusTable(statusEnvironements);

  const { mutate: mutateSyncForm, isLoading: isLoadingSyncForms } = useSyncFormsData();

  const isLoading = isFetchingFormSearchData || isFetchingRecent || isFetchingFormByVersion || isFetchingUpdateForm;

  const crumbs = [{ label: 'Forms', path: '/' }, { label: formName, path: `/${formName}` }, { label: 'Deployment Manager' }];

  const formData: UpdateModalDataType = { environments, selectedVersion: selectedVersion?.label || '', formName };

  const isSyncAvailable = ['int5', 'uat'].includes(statusEnvironements[0]?.highestEnvironment?.toLowerCase() || '');

  const formUpdateData: UpdateModalDataType = {
    environments: updateEnvDropDown(priority, highestEnvironment),
    selectedVersion: selectedVersion?.label || '',
    formName,
  };

  const {
    open: updateFormOpen,
    isOpen: isUpdateFormOpen,
    close: updateFormClose,
    modalData: updateFormData,
    setModalData: setUpdateFormData,
  } = useModalState<UpdateModalDataType>('updateForm');

  const {
    open: deployFormOpen,
    isOpen: isDeployFormOpen,
    close: deployFormClose,
    modalData: deployFormData,
    setModalData: setDeployFormData,
  } = useModalState<UpdateModalDataType>('deployForm');

  const {
    isOpen: isScheduleFormOpen,
    close: scheduleFormClose,
    modalData: scheduleFormData,
    setModalData: setScheduleFormData,
  } = useModalState<UpdateModalDataType>('scheduleForm');

  function submitUpdateForm(formData: UpdateModalDataType | undefined): void {
    setUpdateFormData(formData);

    mutateUpdateForm({
      guid: selectedVersion?.value || '',
      env: formData?.selectedEnvironment,
      isTest: formData?.isTest,
      isLatest: formData?.isLatest,
    });
  }
  const VERSIONS_OPTIONS: { label: string; value: string }[] = formSearchData?.items.map((form) => ({
    label: form.version,
    value: form.questionSet.guid,
  })) || [{ label: '', value: '' }];

  return (
    <Page id="deployment-management" data-testid="deployment-management" isLoading={isLoading}>
      <Header title={`${formName} DEPLOYMENT MANAGER`} />
      <div className="px-8 mt-8 h-full">
        <div className="w-full h-full md:w-9/10 lg:w-4/5 xl:w-[1300px] mx-auto flex flex-col gap-y-2">
          <Breadcrumb crumbs={crumbs} />
          <div className="flex h-full">
            <div className="w-1/2 h-full pl-[8%] pr-[10%] deployment-management-environment-table">
              <CommonTable dataTestId="deployment-management-environment-table" table={environementsTable} />
            </div>
            <div className="w-1/2 h-full pl-[10%] pr-[10%]">
              <div className="bg-white border deployment-management-status">
                <form>
                  <div className="px-[4%] py-[5%]">
                    <FormControl
                      defaultValue={VERSIONS_OPTIONS[0]}
                      options={VERSIONS_OPTIONS}
                      type="select"
                      name="versions"
                      label="Select Form Version"
                      control={control}
                      onChange={(item) => {
                        setSelectedVersion(item!);
                        setDisableFormActivity(false);
                      }}
                    />
                  </div>
                  <CommonTable dataTestId="deployment-management-qa-status-table" table={statusTable} />
                  <div className="flex flex-col space-y-4 px-[4%] py-[5%]">
                    <button
                      className="qe-btn"
                      data-testid="update-test-latest-form-btn"
                      type="button"
                      onClick={() => updateFormOpen(formUpdateData)}
                      disabled={disableFormActivity}
                    >
                      UPDATE IS TEST/LATEST
                    </button>
                    <button
                      className="qe-btn"
                      data-testid="sync-form-data-btn"
                      type="button"
                      disabled={!isSyncAvailable || isLoadingSyncForms || disableFormActivity}
                      onClick={() =>
                        mutateSyncForm({
                          formId: (selectedVersion && selectedVersion.value) ?? '',
                          highestEnvironment: (statusEnvironements && statusEnvironements[0].highestEnvironment?.toLocaleLowerCase()) ?? '',
                        })
                      }
                    >
                      SYNC FORM DATA
                    </button>
                    <button
                      className="qe-btn fill"
                      data-testid="deploy-form-btn"
                      type="button"
                      onClick={() => deployFormOpen(formData)}
                      disabled={disableFormActivity}
                    >
                      DEPLOY
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateFormModal
        isOpen={isUpdateFormOpen}
        closeModal={updateFormClose}
        selectedFormData={updateFormData}
        onChange={submitUpdateForm}
      />
      {isDeployFormOpen && (
        <DeployFormModal
          isOpen={isDeployFormOpen}
          closeModal={deployFormClose}
          selectedFormData={deployFormData}
          onChange={setDeployFormData}
        />
      )}
      <ScheduleFormModal
        isOpen={isScheduleFormOpen}
        closeModal={scheduleFormClose}
        selectedFormData={scheduleFormData}
        onChange={setScheduleFormData}
      />
    </Page>
  );
};
