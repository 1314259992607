import type { FormField, SwitchFormControlConfig } from '../../shared/types';
import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';

import { SwitchField } from '../../../../components/SwitchField/SwitchField';

type SwitchFormControlProps<TValues extends FieldValues> = SwitchFormControlConfig<TValues> & FormField<TValues>;

export const SwitchFormControl = <TValues extends FieldValues>({
  field,
  name,
  controlClassName,
  onChange,
}: SwitchFormControlProps<TValues>): ReactElement => {
  return (
    <SwitchField
      name={name}
      {...field}
      classContainer={controlClassName}
      value={field?.value ?? false}
      onChange={(value) => {
        field?.onChange(value);
        onChange?.(value);
      }}
      data-testid={`${name}-switch`}
      ref={null}
    />
  );
};
