import type { CommonQuestion, CreateEditAnswer } from './schema';
import type { Answer, GradeInfo } from '../../../api/queries/forms/forms.types';
import type {
  Answer as SharedAnswer,
  GetNestedQuestionDetails,
  GetNestedQuestions,
  MapAnswerStateToFormStateParams,
  Metadata,
  RequiredQuestions,
} from '../shared/types';

import { getClientPreferences, isClientPreferences } from './getClientPreferences';
import { getFilters } from './getFilters';
import { getIsInitialNestedAndNestingType } from './getIsInitialNestedAndNestingType';
import { getTopLevelMetadataSchemaKeys } from './getMetadataSchemaKeys';
import { getTags } from './getTags';
import { createEditAnswerValidationSchema } from './schema';
import { NestedFieldName } from '../shared/types';

const getNestedQuestionsDetails = (answer: Answer): GetNestedQuestionDetails => {
  const { nestingType } = getIsInitialNestedAndNestingType(answer);

  if (nestingType === NestedFieldName.nestedQuestions) {
    return {
      nestingType: 'nestedQuestions',
      nestedQuestions: answer?.nestedQuestions?.[0] || '',
    };
  }

  if (nestingType === NestedFieldName.requiredQuestions) {
    return {
      nestingType: 'requiredQuestions',
      requiredQuestions: (answer?.requiredQuestions as RequiredQuestions) || [],
    };
  }

  return {} as GetNestedQuestionDetails;
};

const isMetadata = (metaData: Metadata): boolean => {
  const schemaKeysTopLevel = getTopLevelMetadataSchemaKeys(createEditAnswerValidationSchema);
  const result = false;
  for (const key in metaData) {
    if (!schemaKeysTopLevel.includes(key)) {
      return true;
    }
  }
  return result;
};

export const mapAnswerStateToFormState = ({ answer, itemSearch, defaultAnswers }: MapAnswerStateToFormStateParams): CreateEditAnswer => {
  const { initialIsNestQuestion } = getIsInitialNestedAndNestingType(answer);
  const isRequiresPhotosDefault = answer ? Boolean(answer?.metaData?.takePhoto) : true;
  const isNumericEntryDefault = Boolean(answer?.chooseQuantity);
  const isDefaultAnswerDefault = answer.value === defaultAnswers;
  const isAnnouncementsDefault = Boolean(answer?.announcements?.length);

  const artCode = answer?.metaData?.artCode;
  const filters = getFilters(answer);

  return {
    value: answer.value ?? '',
    switchControls: {
      isGradeAdjust: Boolean(answer.metaData?.artCode?.itemCode),
      isNestQuestion: initialIsNestQuestion,
      isRequiresPhoto: isRequiresPhotosDefault,
      isFreeFormText: Boolean(answer.showNotes),
      isClientPreferences: isClientPreferences(answer?.tags?.[0] ?? ''),
      isFilters: Boolean(answer?.tags?.length),
      isNumericEntry: isNumericEntryDefault,
      isDefaultAnswer: isDefaultAnswerDefault,
      isAnnouncements: isAnnouncementsDefault,
      isMetadata: answer.metaData ? isMetadata(answer.metaData) : false,
    },
    metaData: {
      ...answer.metaData,
      takePhoto: {
        type: answer?.metaData?.takePhoto?.type ?? 'damage',
        title: answer?.metaData?.takePhoto?.title ?? '',
        description: answer?.metaData?.takePhoto?.description ?? '',
      },
      artCode: {
        itemCode: artCode?.itemCode ?? '',
        itemSubCode: artCode?.itemSubCode ?? '',
        damageCode: artCode?.damageCode ?? '',
        severityCode: artCode?.severityCode ?? '',
        itemSearch,
      },
    },
    tireInfo: (answer?.gradeInfo ?? [])[0]?.tireInfo,
    damages: (answer?.gradeInfo ?? [])[0]?.damages,
    gradeFlag: answer?.gradeFlag,
    helpText: answer.helpText ?? '',
    hideBuyerDisplay: answer.hideBuyerDisplay ?? false,
    connotation: answer.connotation ?? 0,
    nestedQuestionDetails: getNestedQuestionsDetails(answer),
    showNotes: answer.showNotes ?? false,
    notesLabel: answer.notesLabel ?? '',
    tags: [],
    ...getClientPreferences(answer),
    ...filters,
    maxQuantity: answer.maxQuantity ?? 0,
    chooseQuantity: answer.chooseQuantity ?? false,
    announcements: answer.announcements ?? [],
  };
};

const defaultNestedQuestions: GetNestedQuestions = {
  requiredQuestions: undefined,
  nestedQuestions: undefined,
};

const defaultFreeFormText = {
  showNotes: undefined,
  notesLabel: undefined,
};

const getNestedQuestions = (nestedQuestionsDetails: CreateEditAnswer['nestedQuestionDetails']): GetNestedQuestions => {
  if (!nestedQuestionsDetails) {
    return defaultNestedQuestions;
  }

  if (nestedQuestionsDetails.nestingType === NestedFieldName.requiredQuestions) {
    return { requiredQuestions: nestedQuestionsDetails.requiredQuestions, nestedQuestions: undefined };
  }

  if (nestedQuestionsDetails.nestingType === NestedFieldName.nestedQuestions) {
    return { nestedQuestions: [nestedQuestionsDetails.nestedQuestions], requiredQuestions: undefined };
  }

  return defaultNestedQuestions;
};

const getFreeFormText = (answer: Answer): { showNotes?: CreateEditAnswer['showNotes']; notesLabel?: CreateEditAnswer['notesLabel'] } => {
  if (answer.notesLabel || answer.switchControls?.isFreeFormText) {
    return {
      showNotes: true,
      notesLabel: answer.notesLabel,
    };
  }

  return defaultFreeFormText;
};
const getGradeInfo = (answer: CreateEditAnswer): GradeInfo | undefined => {
  const tireInfo = answer.tireInfo ?? undefined;
  const damages = answer.damages ?? undefined;

  return !tireInfo && !damages ? undefined : [{ tireInfo, damages }];
};

const getGradeFlag = (answer: CreateEditAnswer) => {
  return Object.keys(answer.gradeFlag ?? {}).length ? answer.gradeFlag : undefined;
};

const getAnnouncements = (answer: Answer): { announcements?: CreateEditAnswer['announcements'] } => {
  if (answer.announcements) {
    return {
      announcements: answer.announcements,
    };
  }

  return {};
};

export const mapCreateEditFormStateToAnswerState = (answer: CreateEditAnswer): Answer => {
  const isTakePhotoRemovedOrIgnored = !Boolean(answer.metaData?.takePhoto?.title || answer.metaData?.takePhoto?.description);
  const { switchControls } = answer;

  const additionalMetaData = switchControls?.isMetadata ? answer.metaData : undefined;

  return {
    answerGroups: answer.answerGroups,
    value: answer.value,
    helpText: answer.helpText,
    hideBuyerDisplay: answer.hideBuyerDisplay,
    connotation: answer.connotation,
    ...getFreeFormText(answer),
    ...getNestedQuestions(answer.nestedQuestionDetails),
    ...getAnnouncements(answer),
    ...getTags(answer),
    ...(answer.chooseQuantity && {
      maxQuantity: answer.maxQuantity,
      chooseQuantity: answer.chooseQuantity,
    }),
    metaData: {
      ...additionalMetaData,
      takePhoto: isTakePhotoRemovedOrIgnored ? undefined : answer.metaData?.takePhoto,
      artCode: switchControls?.isGradeAdjust ? answer.metaData?.artCode : undefined,
    },
    gradeInfo: switchControls?.isGradeAdjust ? getGradeInfo(answer) : undefined,
    gradeFlag: switchControls?.isGradeAdjust ? getGradeFlag(answer) : undefined,
  };
};

export const mapCopiedAnswersToState = (answerItem: SharedAnswer, { artMapping }: Pick<CommonQuestion, 'artMapping'>) => {
  const itemCode = artMapping?.artCode?.itemCode ? artMapping?.artCode?.itemCode : answerItem?.metaData?.artCode?.itemCode;

  const itemSubCode = artMapping?.artCode?.itemSubCode ? artMapping?.artCode?.itemSubCode : answerItem?.metaData?.artCode?.itemSubCode;

  return answerItem.metaData?.artCode
    ? {
        ...answerItem,
        metaData: {
          ...answerItem.metaData,
          artCode: {
            ...answerItem.metaData.artCode,
            itemCode,
            itemSubCode,
          },
        },
      }
    : answerItem;
};
