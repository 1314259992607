import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';
import Calendar from 'react-calendar';

import type { UpdateModalDataType } from '../shared/types';
import type { FC, ReactElement } from 'react';

import { Modal } from '../../../components/Modal/Modal';
import { SwitchField } from '../../../components/SwitchField/SwitchField';

type ScheduleFormModalModalProps = Readonly<{
  isOpen: boolean;
  closeModal: () => void;
  onChange: (UpdateModalDataType?: UpdateModalDataType) => void;
  selectedFormData?: UpdateModalDataType;
}>;

type DateType = Date | null;

type CalendarType = DateType | [DateType, DateType];

export const ScheduleFormModal: FC<ScheduleFormModalModalProps> = ({
  isOpen,
  closeModal,
  selectedFormData,
  onChange,
}): ReactElement | null => {
  const version = selectedFormData?.selectedVersion || '';
  const name = selectedFormData?.formName || '';
  const formName = `${name} ${version} - Scheduled Production Deployment`;
  const [isLatest, updateIsLatest] = useState<boolean>(true);
  const [isTest, updateIsTest] = useState<boolean>(true);
  const [date, setDate] = useState<CalendarType>(new Date());
  const statusText = { active: 'ON', inactive: 'OFF' };

  const closeUpdateModal = () => {
    submitChange();
    closeModal();
  };

  const submitChange = () => {
    if (selectedFormData) {
      const copyScheduleFormData = {
        ...selectedFormData,
        isTest,
        isLatest,
      };
      onChange(copyScheduleFormData);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} modalTitle={formName}>
      <>
        <div className="grid grid-cols-auto gap-4" data-testid="schedule-modal-form">
          <div className="col-start-1 col-span-5">
            <div className="flex flex-row">
              <div className="basis-2/3 py-3 px-3">Is Test</div>
              <div className="basis-1/4">
                <SwitchField
                  data-testid="isTestField"
                  name="isTestField"
                  value={isLatest}
                  onChange={(value) => updateIsLatest(value)}
                  statusText={statusText}
                />
              </div>
            </div>
          </div>
          <div className="col-start-6 col-span-4">
            <div className="flex flex-row">
              <div className="basis-2/3 py-2 px-2">Is Latest</div>
              <div className="basis-1/4">
                <SwitchField
                  data-testid="isLatestField"
                  name="isLatestField"
                  value={isTest}
                  onChange={(value) => updateIsTest(value)}
                  statusText={statusText}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid mt-10">
          <div className="justify-self-center">
            <Calendar onChange={(date) => setDate(date)} value={date} view="month" />
          </div>
          <div className="text-center pt-3">
            <p>Note: Deployment time is set at 7:00 PM EST on selected date</p>
          </div>
        </div>
        <div className="grid grid-cols-auto gap-4 mt-20">
          <div className="col-start-1 col-span-5 p-2">
            <button className="qe-btn" onClick={closeModal}>
              CANCEL DEPLOYMENT
            </button>
          </div>
          <div className="col-start-7 col-span-3 p-2">
            <button className="qe-btn fill w-full" type="button" onClick={closeUpdateModal}>
              SCHEDULE DEPLOYMENT
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
