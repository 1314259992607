import { z } from 'zod';

export const createFormTypeValidationSchema = z.object({
  name: z
    .string()
    .nonempty('Name is required')
    .max(30, 'Maximum of 30 characters allowed')
    .refine((value) => {
      const pattern = /^[a-z0-9\-]+$/;
      return pattern.test(value);
    }, 'Only lowercase letters, numbers, and dashes are allowed'),
  description: z.string().nonempty('Description is required').max(256, 'Maximum of 256 characters allowed'),
});

export const copyFormTypeValidationSchema = z.object({
  name: z
    .string()
    .nonempty('Name is required')
    .max(30, 'Maximum of 30 characters allowed')
    .refine((value) => {
      const pattern = /^[a-z0-9\-]+$/;
      return pattern.test(value);
    }, 'Only lowercase letters, numbers, and dashes are allowed'),
  description: z.string().nonempty('Description is required').max(256, 'Maximum of 256 characters allowed'),
  version: z.string().nonempty('Version is required'),
});
